import './App.css';

import Login from './Pages/login/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './Pages/private/PrivateRoute';

import GameHistoryTable from './Pages/GameHistoryTable';
import GameLobby from './Pages/GameLobby/GameLobby';
import { AtlantisLegend } from './Components/atlantis_legend/AtlantisLegend';
import Slot from './Components/flames_of_fortune/Slot';
import { MexTEST } from './Components/MexTEST1/MexTEST';
import SlotNew from './Components/flames_of_fortune/SlotNew';

function App() {
  return (
    <>
      <BrowserRouter>
        {/* <RemoveScroll> */}
          <Routes>
            <Route path="/gameLobby" element={<GameLobby />} />
            <Route path="/" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/atlantis-legend" element={<SlotNew />} />
              <Route path="/flames-of-fortune" element={<Slot />} />
              {/* <Route path="/atlantis-legend" element={<AtlantisLegend />} /> */}
              <Route path="/MexTest" element={<MexTEST />} />
            </Route>
            <Route path="/GameHistoryTable" element={<GameHistoryTable />} />
          </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
